import React from 'react'
import PropTypes from 'prop-types'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'

const ImageBanner = ({ src, overlay = false, className = '' }) => {
  const ref = useOnScrollAnimate()
  return (
    <section ref={ref} className={`block ${overlay && 'overlay'}`}>
      <img
        className={`block w-full h-full object-cover anim fadeIn delay-500 ${
          overlay && 'filter grayscale'
        } ${className}`}
        src={src}
        alt=""
      />
    </section>
  )
}

ImageBanner.propTypes = {
  src: PropTypes.string,
  overlay: PropTypes.bool,
  maxH: PropTypes.string,
  className: PropTypes.string
}

export default ImageBanner
