import React, { useEffect, useRef } from 'react'

const Header = () => {
  const ref = useRef()
  useEffect(() => {
    setTimeout(() => {
      ref.current.classList.add('has_transition')
    }, 100)
  }, [])

  return (
    <header
      ref={ref}
      className="bg-primary py-5 md:py-10 px-4 text-white before_enter"
    >
      <h1 className="font-extralight text-center sm:text-2xl tracking-widest">
        <span className="inline-block anim fadeInRight delay-500">LUPITA</span>
        <span className="inline-block mx-4 anim fadeIn delay-500">&</span>
        <span className="inline-block anim fadeInLeft delay-500">ANDRES</span>
      </h1>
    </header>
  )
}

export default Header
