import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

const useTimeLeft = (dateTo) => {
  const [months, setMonths] = useState(0)
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const { monthsLeft, hoursLeft, daysLeft, minutesLeft } = timeLeft(dateTo)
      setMonths(monthsLeft)
      setDays(daysLeft)
      setHours(hoursLeft)
      setMinutes(minutesLeft)
    }, 1000)
    return () => clearInterval(interval)
  }, [dateTo])

  const timeLeft = (date) => {
    const now = dayjs()
    const dateCalc = dayjs(date)

    const monthsLeftCalc = dateCalc.diff(now, 'month')
    const monthsLeft = monthsLeftCalc >= 0 ? monthsLeftCalc : 0
    const dateForDays = dayjs(dayjs(now).add(monthsLeft, 'month'))

    const daysLeftCalc = dateCalc.diff(dateForDays, 'day')
    const daysLeft = daysLeftCalc >= 0 ? daysLeftCalc : 0
    const dateForHours = dayjs(
      dayjs(now).add(monthsLeft, 'month').add(daysLeft, 'day')
    )

    const hoursLeftCalc = dateCalc.diff(dateForHours, 'hour')
    const hoursLeft = hoursLeftCalc >= 0 ? hoursLeftCalc : 0
    const dateForMinutes = dayjs(
      dayjs(now)
        .add(monthsLeft, 'month')
        .add(daysLeft, 'day')
        .add(hoursLeft, 'hour')
    )

    const minutesLeftCalc = dateCalc.diff(dateForMinutes, 'minute') + 1
    const minutesLeft = minutesLeftCalc >= 0 ? minutesLeftCalc : 0

    return {
      monthsLeft,
      daysLeft,
      hoursLeft,
      minutesLeft
    }
  }

  return {
    months,
    days,
    hours,
    minutes
  }
}

export default useTimeLeft
