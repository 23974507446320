import React from 'react'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'
import Title from '../components/Title'
import HotelCard from '../components/HotelCard'

const Hotels = () => {
  const ref2 = useOnScrollAnimate()
  return (
    <section ref={ref2} className="py-12 text-center">
      <Title text="Sugerencias de Hospedaje" className="mb-8" />
      <div className="py-4 flex justify-center md:gap-10 flex-wrap mb-2">
        <div className="w-full lg:w-1/4 mb-10 lg:mb-0 anim fadeInUp delay-500">
          <HotelCard
            name="Hotel Casa Grande"
            srcMaps="https://maps.app.goo.gl/fCybBGBRXQswvz788"
            address="Portal Matamoros 98, Centro,<br /> Morelia, Mich."
          />
        </div>
        <div className="w-full lg:w-1/4 mb-10 lg:mb-0 anim fadeInUp delay-800">
          <HotelCard
            name="Best Western Plus Gran Hotel Morelia"
            srcMaps="https://maps.app.goo.gl/3rNvs6B9WvLa96s29"
            address="Av. Ventura Puente,<br /> Perif. Paseo de la República Esq, Félix Ireta, <br /> Morelia, Mich."
          />
        </div>
        <div className="w-full lg:w-1/4 mb-10 lg:mb-0 anim fadeInUp delay-1100">
          <HotelCard
            name="Hotel Misión Catedral Morelia"
            srcMaps="https://maps.app.goo.gl/ECoiiaHrqkuCaSW57"
            address="Ignacio Zaragoza 37, <br />Centro histórico de Morelia, <br /> Morelia, Mich."
          />
        </div>
      </div>
    </section>
  )
}

export default Hotels
