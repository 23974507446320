import React from 'react'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'

const Info = () => {
  const ref = useOnScrollAnimate()

  return (
    <section ref={ref} className="text-center">
      <div className="mx-auto my-0 w-10/12 sm:w-8/12 bg-white py-14 relative -mt-20 md:-mt-32 lg:-mt-36 bg-opacity-80 anim fadeInUp">
        <div className="w-11/12 md:w-8/12 mx-auto">
          <h1 className="great-vibes text-primary text-7xl md:text-8xl mb-5 leading-[0.95] md:leading-[1]">
            Lupita <br className="md:hidden" />
            <span className="text-gray-600 text-4xl md:text-6xl mx-2">&</span>
            <br className="md:hidden" /> Andrés
          </h1>
          <h2 className="md:text-xl tracking-wide anim fadeInUp delay-500">
            <strong>NUESTRA BODA</strong>
          </h2>
          <h2 className="md:text-lg mb-12 tracking-c1 anim fadeInUp delay-800">
            7 de Septiembre 2024
          </h2>
          <p className="uppercase px-4 anim fadeInUp delay-1000">
            Hace <strong>10 años</strong> comenzamos esta historia, hoy tomamos
            la decisión de continuar caminando unidos, celebremos juntos este
            gran día.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Info
