import { useEffect, useState } from 'react'

function useIntersectionObserver(
  elementRef,
  {
    threshold = 0,
    root = null,
    rootMargin = '-120px',
    freezeOnceVisible = false
  }
) {
  const [entry, setEntry] = useState()

  const frozen = entry?.isIntersecting && freezeOnceVisible

  const updateEntry = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('has_transition')
        setTimeout(() => {
          observer.unobserve(entry.target)
        }, 100)
      }
    })
    setEntry(entry)
  }

  useEffect(() => {
    const node = elementRef?.current // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    node.classList.add('before_enter')

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen])

  return entry
}

export default useIntersectionObserver
