import React from 'react'
import MapCard from '../components/MapCard'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'
import temploCruz from '../assets/img/templo_cruz.jpg'
import bodaCivil from '../assets/img/boda_civil.jpg'
import terrazaCasaGrande from '../assets/img/terraza_casa_grande.jpg'

const Locations = () => {
  const ref2 = useOnScrollAnimate()
  return (
    <section className="py-12 text-center">
      <div ref={ref2} className="py-4 flex justify-around flex-wrap mb-2">
        <div className="w-full lg:w-1/3 mb-10 lg:mb-0 anim fadeInUp delay-500">
          <MapCard
            title="CEREMONIA RELIGIOSA"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1579.3037543584092!2d-101.1906304617275!3d19.70246664120526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e722e5e82f1%3A0xb6ea93eb7df3d462!2sRector%C3%ADa%20de%20la%20Santa%20Cruz!5e0!3m2!1ses-419!2smx!4v1720849346671!5m2!1ses-419!2smx"
            image={temploCruz}
            srcMaps="https://maps.app.goo.gl/jzqt21ycmcrQGoky6"
          >
            <strong>TEMPLO DE LA CRUZ</strong> <br />
            Avenida Francisco I. Madero S/N Centro histórico, Morelia, Mich.{' '}
            <br />
            <strong className="text-xl">17:00 HRS.</strong>
          </MapCard>
        </div>
        <div className="w-full lg:w-1/3 mb-10 lg:mb-0 anim fadeInUp delay-800">
          <MapCard
            title="CEREMONIA CIVIL"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.2475873175463!2d-101.19678798859117!3d19.70208053194183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e70607dea63%3A0x937bc9ca56599a37!2sCasa%20Grande!5e0!3m2!1ses-419!2smx!4v1720849799916!5m2!1ses-419!2smx"
            image={bodaCivil}
            srcMaps="https://maps.app.goo.gl/fCybBGBRXQswvz788"
          >
            <strong>HOTEL CASA GRANDE</strong> <br />
            Portal Matamoros 98, Centro, Morelia, Mich. <br />
            <strong className="text-xl">18:30 HRS.</strong>
          </MapCard>
        </div>
        <div className="w-full lg:w-1/3 mb-10 lg:mb-0 anim fadeInUp delay-1100">
          <MapCard
            title="RECEPCIÓN"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.2475873175463!2d-101.19678798859117!3d19.70208053194183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e70607dea63%3A0x937bc9ca56599a37!2sCasa%20Grande!5e0!3m2!1ses-419!2smx!4v1720849799916!5m2!1ses-419!2smx"
            image={terrazaCasaGrande}
            srcMaps="https://maps.app.goo.gl/fCybBGBRXQswvz788"
          >
            <strong>TERRAZA HOTEL CASA GRANDE</strong> <br />
            Portal Matamoros 98, Centro, Morelia, Mich. <br />
            <strong className="text-xl">19:30 HRS.</strong>
          </MapCard>
        </div>
      </div>
    </section>
  )
}

export default Locations
