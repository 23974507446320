import React from 'react'
// import mesaRegalos from '../assets/img/mesa_regalos_title.svg'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'

const Footer = () => {
  const ref2 = useOnScrollAnimate()
  return (
    <section
      ref={ref2}
      className="pt-8 pb-16 bg-[#4D4D4D] text-center text-white"
    >
      <p className="leading-5 anim fadeInUp delay-300">
        &copy; DESARROLLADO POR <br />
        <a
          className="underline hover:text-blue-300"
          href="https://nodolab.com.mx"
          target="_blank"
          rel="noreferrer"
        >
          NODOLAB
        </a>
      </p>
    </section>
  )
}

export default Footer
