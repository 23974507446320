import React from 'react'
import PropTypes from 'prop-types'

const NumberCard = ({ number, text, className }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <div className="flex items-center justify-center mb-2 w-16 h-16 md:w-28 md:h-20 border border-white box-shadow">
        <span className="text-3xl md:text-4xl lg:-mt-4">{number}</span>
      </div>
      <p className="md:tracking-c1 text-xs">{text}</p>
    </div>
  )
}

NumberCard.propTypes = {
  number: PropTypes.number,
  text: PropTypes.string,
  className: PropTypes.string
}

export default NumberCard
