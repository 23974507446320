import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ text, color = 'primary', className = '' }) => {
  return (
    <h1
      className={`great-vibes text-${color} text-5xl anim fadeIn delay-300 ${className}`}
    >
      {text}
    </h1>
  )
}

Title.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
}

export default Title
