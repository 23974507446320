import React from 'react'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'

const Parents = () => {
  const ref4 = useOnScrollAnimate()
  return (
    <section ref={ref4} className="bg-primary py-16 text-white ">
      <div className="flex justify-center flex-wrap gap-10 md:flex-nowrap md:gap-0">
        <div className="w-full lg:w-1/3 text-center anim fadeInUp delay-500">
          <h2 className="text-3xl great-vibes font-semibold pb-2">
            Padres de la Novia
          </h2>
          <p>María Guadalupe Chagolla Farías</p>
          <p>Moisés Díaz Díaz ✝</p>
        </div>
        <div className="w-full lg:w-1/3 text-center anim fadeInUp delay-800">
          <h2 className="text-3xl great-vibes font-semibold pb-2">
            Padres del Novio
          </h2>
          <p>Ma. Gpe. Leticia Gutiérrez Cervantes ✝</p>
          <p>Armando Guillén Osorio</p>
        </div>
        <div className="w-full lg:w-1/3 text-center anim fadeInUp delay-1100">
          <h2 className="text-3xl great-vibes font-semibold pb-2">Padrinos</h2>
          <p>Raymundo López Olvera</p>
          <p>Nancy Verónica Rodriguez Fernández</p>
        </div>
      </div>
    </section>
  )
}

export default Parents
