import { useRef } from 'react'
import useIntersectionObserver from './useIntersectionObserver'

const useOnScrollAnimate = () => {
  const ref = useRef()
  useIntersectionObserver(ref, {})

  return ref
}

export default useOnScrollAnimate
