import React from 'react'
import lupitaAndres1 from '../assets/img/lupita_andres_1.jpg'
import lupitaAndres2 from '../assets/img/lupita_andres_2.jpg'
import lupitaAndres3 from '../assets/img/lupita_andres_3.jpg'
import lupitaAndres7 from '../assets/img/lupita_andres_7.jpg'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'

const Gallery = () => {
  const refs = [
    useOnScrollAnimate(),
    useOnScrollAnimate(),
    useOnScrollAnimate(),
    useOnScrollAnimate()
  ]
  const images = [lupitaAndres1, lupitaAndres2, lupitaAndres3, lupitaAndres7]
  return (
    <section className="py-12 ">
      <div className="w-full flex flex-wrap items-center justify-center gap-4">
        {images.map((img, i) => (
          <div
            ref={refs[i]}
            key={i}
            className="w-10/12 sm:w-5/12 lg:w-2/12 h-52"
          >
            <img
              className={`w-full h-full block object-cover box-shadow anim fadeInUp delay-${
                i * 200
              }`}
              src={img}
              alt=""
            />
          </div>
        ))}
      </div>
    </section>
  )
}

export default Gallery
