import React from 'react'
import Title from '../components/Title'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'

const Gifts = () => {
  const ref = useOnScrollAnimate()
  return (
    <section ref={ref} className="py-12 px-4 bg-primary text-center text-white">
      <Title text="Mesa de Regalos" color="white" className="mb-8" />
      <p className="mb-8 md:mb-4 text-sm md:text-base uppercase anim fadeInUp delay-300">
        Estamos emocionados de compartir nuestro día especial contigo. <br />
        Si planeabas darnos un regalo,
        <br />
        te agradeceríamos considerar una contribución en efectivo
        <br />
        para nuestra luna de miel soñada.
      </p>
      <p className="anim fadeInUp delay-500">
        <strong>NOMBRE</strong>
      </p>
      <p className="anim fadeInUp delay-500">
        Andrés Eduardo Guillén Gutiérrez
      </p>
      <p className="anim fadeInUp delay-500">
        <strong>CUENTA BBVA</strong>
      </p>
      <p className="mb-4 md:mb-0 anim fadeInUp delay-500">268 399 8290</p>
      <p className="anim fadeInUp delay-500">
        <strong>CLABE</strong>
      </p>
      <p className="mb-4 md:mb-0 anim fadeInUp delay-500">
        012 470 02683998290 4
      </p>
      <p className="anim fadeInUp delay-500">
        <small>
          <i>*No olvides agregar tu nombre en el concepto</i>
        </small>
      </p>
    </section>
  )
}

export default Gifts
