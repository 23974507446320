import React from 'react'
import NumberCard from '../components/NumberCard'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'
import useTimeLeft from '../hooks/useTimeLeft'

const Counter = () => {
  const ref = useOnScrollAnimate()
  const { months, days, hours, minutes } = useTimeLeft('2024-09-07 17:00')

  return (
    <section ref={ref} className="py-12 bg-primary text-center text-white">
      <h2 className="text-xl tracking-wide anim fadeIn">FALTA MUY POCO</h2>
      <div className="flex justify-center gap-3 md:gap-5 py-5">
        {months ? (
          <NumberCard
            className="anim fadeInUp delay-200"
            number={months}
            text="MESES"
          />
        ) : null}
        <NumberCard
          className="anim fadeInUp delay-500"
          number={days}
          text="DÍAS"
        />
        <NumberCard
          className="anim fadeInUp delay-800"
          number={hours}
          text="HORAS"
        />
        <NumberCard
          className="anim fadeInUp delay-1000"
          number={minutes}
          text="MINUTOS"
        />
      </div>
    </section>
  )
}

export default Counter
