import React from 'react'
import PropTypes from 'prop-types'

const HotelCard = ({ name, srcMaps, address }) => {
  return (
    <div className="text-center">
      <h3 className="text- text-xl mb-2 font-bold">{name}</h3>
      <p
        className="mb-4"
        dangerouslySetInnerHTML={{
          __html: address
        }}
      ></p>
      <div className="text-center pb-6 pt-2">
        <a
          href={srcMaps}
          target="_blank"
          rel="noopener noreferrer"
          className="pt-1 pb-3 px-6 rounded-lg bg-primary hover:bg-opacity-20 text-white"
        >
          Ver en Google Maps
        </a>
      </div>
    </div>
  )
}

HotelCard.propTypes = {
  name: PropTypes.string,
  srcMaps: PropTypes.string,
  address: PropTypes.string
}

export default HotelCard
