import React from 'react'
import PropTypes from 'prop-types'

const MapCard = ({ src, title, children, image, srcMaps }) => {
  return (
    <>
      <h3 className="text- text-lg mb-2">{title}</h3>
      {image ? (
        <div className="mb-4 w-10/12 h-64 mx-auto border-t pt-4 border-gray-200">
          <img
            src={image}
            alt={title}
            className="shadow-md block w-full h-full"
          />
        </div>
      ) : (
        <div className="mb-4 w-10/12 mx-auto border-t pt-4 border-gray-200">
          <iframe
            src={src}
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            className="shadow-md"
          ></iframe>
        </div>
      )}
      <div className="text-center pb-6 pt-2">
        <a
          href={srcMaps}
          target="_blank"
          rel="noopener noreferrer"
          className="pt-1 pb-3 px-6 rounded-lg bg-primary hover:bg-opacity-20 text-white"
        >
          Ver en Google Maps
        </a>
      </div>
      <p className="text-center text-sm">{children}</p>
    </>
  )
}

MapCard.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  srcMaps: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default MapCard
