import React from 'react'
import WhatsAppIcon from '../assets/img/whatsapp.svg'
import useOnScrollAnimate from '../hooks/useOnScrollAnimate'
import Title from '../components/Title'

const Confirmation = () => {
  const ref1 = useOnScrollAnimate()
  const ref3 = useOnScrollAnimate()
  return (
    <section className="py-12 text-center">
      <div ref={ref1} className="mb-8">
        <Title text="Confirmación de Asistencia" />
      </div>
      <div ref={ref3}>
        <p className="text-primary font-bold md:text-lg mb-2 md:mb-4 anim fadeInUp delay-500">
          AGRADECEMOS TU CONFIRMACIÓN AL
        </p>
        <p className="mb-3 md:mb-4 text-primary text-xl md:text-2xl flex justify-center anim fadeInUp delay-800">
          <a
            href="https://wa.me/+524432417010"
            target="_blank"
            rel="noreferrer"
            className="flex items-end"
          >
            <span className="inline-block w-5 md:w-6 mr-3">
              <img src={WhatsAppIcon} alt="" />
            </span>
            <span>443 241 7010</span>
          </a>
        </p>
        <p className="md:text-lg anim fadeInUp delay-1000">
          CÓDIGO DE VESTIMENTA
        </p>
        <div className="flex items-center justify-center pt-2 anim fadeInUp delay-1200">
          <strong className="pt-0 pb-1 mb-2 px-3 rounded-md bg-primary text-white">
            ELEGANTE
          </strong>
        </div>
        <p className="md:text-lg text-lg tracking-wide anim fadeInUp delay-1300">
          <strong>- SOLO ADULTOS -</strong>
        </p>
      </div>
    </section>
  )
}

export default Confirmation
